<template>
  <!--begin::Advance Table Widget 9-->
  <div class="card card-custom card-stretch gutter-b">
    <!--begin::Header-->
    <div class="card-header border-0 py-5">
      <h3 class="card-title align-items-start flex-column"></h3>
      
      <div class="card-toolbar">
        <a
          href="#"
          class="btn btn-primary font-weight-bolder font-size-sm"
          @click="create_rule_clicked"
        >
          <i class="menu-icon flaticon2-plus" style="font-size: 1.0em;"></i>
          {{ $t('KVITTO_RULE.NEW_RULE') }}
        </a>
      </div>
    </div>

    <b-row class="ml-4 mr-4">
      <b-col cols="6">
        <div class="bottom-alignment">
          <strong>{{ $t('MEMBER.NUM_ROWS') }}:</strong> {{ total_rows }}
        </div>
      </b-col>
      <b-col cols="6">
        <div class="d-sm-flex justify-content-end align-items-right align-bottom w-100 mb-2">
          <b-form-select
            class="mr-sm-2 mt-2 align-bottom"
            style="max-width: 100px;"
            :options="[10, 25, 50, 100]"
            v-model="per_page"
          />
          <b-pagination
            class="mb-0 mt-2 align-bottom"
            style="padding-left: 0px !important;"
            v-model="current_page"
            :total-rows="total_rows"
            :per-page="per_page"
            aria-controls="kvitto-rule-table"
            first-number
            last-number
          ></b-pagination>
        </div>
      </b-col>
    </b-row>

    <div class="card-body pt-0 table-responsive">
      <b-table
        id="kvitto-rule-table"
        :fields="headers"
        :items="items"
        :per-page="per_page"
        :current-page="current_page"
        sort-by="created_at"
        :sort-desc="true"
        head-variant="light"
        sticky-header
        class="mh-100 table-striped"
      >
        <!-- Column for 'Name' -->
        <template #cell(name)="row">
          <div class="td-contain">{{ row.item.name }}</div>
        </template>

        <!-- Column for 'Company ID' -->
        <template #cell(company_id)="row">
          <div class="td-contain">{{ row.item.company_id }}</div>
        </template>

        <!-- Column for 'From Email' -->
        <template #cell(from_email)="row">
          <div class="td-contain">{{ row.item.from_email }}</div>
        </template>

        <!-- Column for 'Subject Like' -->
        <template #cell(subject_like)="row">
          <div class="td-contain">{{ row.item.subject_like }}</div>
        </template>

        <!-- Column for 'Subject Like Count' -->
        <template #cell(subject_like_count)="row">
          <div class="td-contain">{{ row.item.subject_like_count }}</div>
        </template>

        <!-- Column for 'AI Match Description' -->
        <template #cell(ai_match_description)="row">
          <div class="td-contain">{{ row.item.ai_match_description }}</div>
        </template>

        <!-- Column for 'AI Match Count' -->
        <template #cell(ai_match_count)="row">
          <div class="td-contain">{{ row.item.ai_match_count }}</div>
        </template>

        <!-- Column for 'Forward Email To' -->
        <template #cell(forward_email_to)="row">
          <div class="td-contain">{{ row.item.forward_email_to }}</div>
        </template>

        <!-- Column for 'Created At' -->
        <template #cell(created_at)="row">
          <div class="td-contain">{{ formatDate(row.item.created_at) }}</div>
        </template>

        <!-- Actions Column -->
        <template #cell(actions)="row">
          <div class="justify-content-end d-flex">
            <a
              href="#"
              class="btn btn-icon btn-light btn-sm mr-3"
              @click.prevent="select_rule_clicked(row.item.id)"
              v-b-tooltip="{ title: $t('PAGES.FILES.EDIT_FILE_ICON_TIP'), placement: 'top', boundary: 'window', delay: 500, trigger: 'hover' }"
            >
              <span class="svg-icon svg-icon-md svg-icon-primary">
                <inline-svg src="/assets/svg/Write.svg"></inline-svg>
              </span>
            </a>
            <a
              href="#"
              class="btn btn-icon btn-light btn-sm"
              @click.prevent="delete_rule_clicked(row.item.id)"
              v-b-tooltip="{ title: $t('PAGES.FILES.DELETE_FILE_ICON_TIP'), placement: 'top', boundary: 'window', delay: 500, trigger: 'hover' }"
            >
              <span class="svg-icon svg-icon-md svg-icon-primary">
                <inline-svg src="/assets/svg/Trash.svg"></inline-svg>
              </span>
            </a>
          </div>
        </template>
      </b-table>
      <!--end::Table-->
    </div>
    <!--end::Body-->
  </div>
  <!--end::Advance Table Widget 9-->
</template>

<script>
import TipLabel from '@/view/components/TipLabel.vue';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import dayjs from 'dayjs';

export default {
  name: 'KvittoRulesTable',
  props: ['items', 'currentCompanyId'],
  emits: ['create_rule_clicked', 'select_rule_clicked', 'delete_rule_clicked'],
  mixins: [toasts],
  components: { TipLabel },
  data() {
    return {
      current_page: 1,
      per_page: 25,
      options: [10, 25, 50, 100],
      headers: [
        {
          key: 'name',
          label: this.$t('KVITTO_RULE.NAME'),
          sortable: true,
          thClass: '',
          tdClass: 'td-contain',
        },
        {
          key: 'from_email',
          label: this.$t('KVITTO_RULE.FROM_EMAIL'),
          sortable: true,
          thClass: '',
          tdClass: 'td-contain',
        },
        {
          key: 'from_original_email',
          label: this.$t('KVITTO_RULE.FROM_ORIGINAL_EMAIL'),
          sortable: true,
          thClass: '',
          tdClass: 'td-contain',
        },
        {
          key: 'forward_email_to',
          label: this.$t('KVITTO_RULE.FORWARD_EMAIL_TO'),
          sortable: true,
          thClass: '',
          tdClass: 'td-contain',
        },
        {
          key: 'actions',
          label: this.$t('PAGES.FILES.HANDLE'),
          thClass: 'w-150px text-right pr-22',
        },
      ],
    };
  },
  computed: {
    total_rows() {
      return this.items.length;
    },
  },
  methods: {
    create_rule_clicked() {
      this.$emit('create_rule_clicked');
    },
    select_rule_clicked(rule_id) {
      this.$emit('select_rule_clicked', rule_id);
    },
    delete_rule_clicked(rule_id) {
      this.$emit('delete_rule_clicked', rule_id);
    },
    formatDate(dateString) {
      return dayjs(dateString).format('YYYY-MM-DD HH:mm:ss');
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/sass/components/forms/_memlist_checkbox.scss";
@import "@/assets/sass/components/forms/_memlist_table.scss";

</style>
