<template>
  <div>
    <input hidden type="file" id="file" ref="file" v-on:change="handle_file_upload()" />

    <KvittoTable
      :show_connect="false"
      :items="kvittos"
      @create_kvitto_clicked="create_kvitto_clicked"
      @select_kvitto_clicked="select_kvitto"
      @delete_kvitto_clicked="delete_kvitto_clicked"
      @download_kvitto_clicked="download_kvitto"
    />

    <KvittoEditorModal
      :item="selected_kvitto"
      ref="kvitto-editor-modal"
      @updated="kvitto_updated"
      @download_kvitto="download_kvitto"
    />

  </div>
  
</template>


<style lang="scss" scoped>
@import "@/assets/sass/components/forms/_memlist_checkbox.scss";
</style>

<script>

import axios from 'axios';
import { mapGetters } from 'vuex';
import { get_base_url, downloadWithAxios } from '@/core/services/fileDownload';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import Confirm from '@/view/components/Confirm.vue';
import TipLabel from '@/view/components/TipLabel.vue';

import KvittoEditorModal from '@/view/pages/ml/kvitto/KvittoEditorModal.vue';
import KvittoTable from '@/view/pages/ml/kvitto/KvittoTable.vue';

export default {
  name: 'TabKvittoTable',
  mixins: [ toasts ],
  components: {
    KvittoTable,
    KvittoEditorModal,
    Confirm,
    TipLabel,
  },
  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'periods', 'customer'])
  },
  mounted() {
    this.load_kvittos();
  },
  data() {
    return {
      selected_kvitto: {},
      kvittos: [],
    };
  },
  watch: {
    currentCompanyId(newValue, oldvalue) {
      if (newValue) {
        this.load_kvittos();
      }
    },
    currentPeriodId(newValue, oldValue) {
      if (this.currentCompanyId) {
        this.load_kvittos();
      }
    }
  },
  methods: {

    async delete_kvitto_clicked(kvitto_id) {
      const res = await axios.delete(`/kvitto/${kvitto_id}`);

      if (res.status === 204) {
        this.kvittos = this.kvittos.filter(item => item.id !== kvitto_id);
      }
    },

    create_kvitto_clicked() {
      this.select_file_for_upload();
    },

    download_kvitto(file_id) {
      this.downloadFile(file_id);
    },

    select_kvitto(kvitto_id) {
      this.selected_kvitto = this.kvittos.find(item => item.id === kvitto_id);

      this.$refs['kvitto-editor-modal'].show();
    },

    kvitto_updated(kvitto) {
      this.selected_kvitto = kvitto;
      this.toastr('success', this.$t('COMMON.OK'), this.$t('FILE.UPDATED'));

      this.$refs['kvitto-editor-modal'].hide();
    },

    async load_kvittos() {
      const res = await axios.get(`/kvitto/customer/${this.customer.id}`);

      if (res.status === 200) {
        this.kvittos = res.data;
      }
    },

    handle_file_upload() {
      this.file = this.$refs.file.files[0];

      if (this.file.size > (50000000)) {
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.FILES.MUST_BE_LESS'));
        return;
      }

      this.upload_file();
    },
    saveFile() {
      let that = this;
      axios
        .put(`/file/${this.form.file_id}`, this.form)
        .then(res => {
          if (res.status === 200) {
            let index = that.files.findIndex(item => item.file_id == res.data.file_id);
            that.files[index] = res.data;
            // Vue3 compatability for Vue.set
            that.files = [...that.files];

            this.toastr('success', this.$t('COMMON.OK'), 'Filen har uppdaterats');
          } else {
            this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte spara filen');
          }
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte spara filen');
        });
    },
    select_file_for_upload() {
      this.$refs.file.click();
    },
    upload_file() {
      let formData = new FormData();
      formData.append('file', this.file);
      formData.append('file_id', this.form.file_id);
      formData.append('file_source', 'file');
      formData.append('file_type', 'KVITTO_ACCOUNT_TRANSACTIONS');
      formData.append('form_file_id', this.form.file_id);
      formData.append('company_id', this.currentCompanyId);
      formData.append('period_id', this.currentPeriodId);

      axios
        .post('/fileupload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(res => {
          this.load_transaction_files();

          this.toastr('success', this.$t('COMMON.OK'), this.$t('KVITTO.FILE_UPLOAD_SUCCESS'));
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('KVITTO.FILE_UPLOAD_FAILURE'));
        });
    },
    getFilename(path) {
      return path.replace(/^.*[\\\/]/, '');
    },
    async loadData() {
      if (!this.currentCompanyId) {
        return;
      }
      if (!this.titles) {
        return;
      }

      this.load_transaction_files();
    },
    createFileRecord() {
      axios
        .post('/file', this.form)
        .then(res => {
          if (res.status === 201) {
            this.creating = false;
            this.form = res.data.file;
            this.toastr('success', this.$t('COMMON.OK'), 'Filen skapades');
            this.loadFiles();
          }
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte skapa filen');
        });
    },

    downloadFile(file_id) {

      axios
        .get(`/file/${file_id}`)
        .then(res => {
          downloadWithAxios(get_base_url() + `/dlfile/${res.data.linkstr}`, res.data.name);
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte hämta filen');
        });
    },
    deleteFileClicked(file_id) {
      this.toDelete = file_id;
      this.$refs['deleteFileConfirm'].show();
    },
    onDeleteFileConfirm() {
      this.deleteFile(this.toDelete);
      this.toDelete = null;
    },
    deleteFile(file_id) {
      axios
        .delete(`/file/${file_id}?company_id=${this.currentCompanyId}`)
        .then(res => {
          this.files = this.files.filter(item => item.file_id !== file_id);

          this.creating = false;
          this.editing = false;

          this.toastr('success', this.$t('COMMON.OK'), 'Filen togs bort');
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte ta bort filen');
        });
    },

    getLangText(lang_text, lang) {
      for (var i = 0; i < lang_text.length; ++i) {
        if (lang_text[i].lang === lang) {
          return lang_text[i].text;
        }
      }

      return 'Språköversättning saknas';
    },
    load_transaction_files(show_file_id = undefined) {
      this.files = [];
      axios
        .get(`/file?company_id=${this.currentCompanyId}&period_id=${this.currentPeriodId}&file_type=KVITTO_ACCOUNT_TRANSACTIONS`)
        .then(res => {
          if (res.status === 200) {
            this.files = res.data;

            if (show_file_id !== undefined) {
              const f = this.files.find(item => item.file_id === show_file_id);

              if (f !== undefined) {
                this.form = f;
              }
            }
          }
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte lista filer');
        });
    },
    scrollToEditFile() {
      var that = this;
      setTimeout(function() {
        const el = that.$el.getElementsByClassName('scroll-to-container')[0];

        if (el) {
          const yOffset = -70;
          const y = el.getBoundingClientRect().top + window.pageYOffset + yOffset;

          window.scrollTo({ top: y, behavior: 'smooth' });
        }
      }, 100);
    },

    downloadFileClicked() {
      downloadWithAxios(get_base_url() + `/dlfile/${this.form.linkstr}`, this.form.name);
    },
    uploadToFortnoxClicked() {
      axios
        .post(`/fortnox/file`, {
          file_id: this.form.file_id,
          path: '/'
        })
        .then(res => {
          if (res.status === 201) {
            this.toastr('success', this.$t('COMMON.OK'), 'Fil skickad till Fortnox');
          }
          else if (res.status === 404) {
            this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte hitta fil');
          }
          else {
            this.toastr('danger', this.$t('COMMON.ERROR'), 'Odefinerat fel');
          }
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), 'Gick inte att skicka till Fortnox');
        });
    }
  }
};
</script>
