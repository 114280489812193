<template>
  <b-modal size="lg" ref="transaction-edit-modal" hide-footer>
    
    <b-form class="mt-8" v-if="local_item && local_item.account">

      <b-row>
        <b-col md="6" sm="12">
          <b-form-group :label="$t('KVITTO.ACCOUNT.NAME')">
            <b-form-input :disabled="true" v-model="local_item.account.name" type="text" :placeholder="$t('KVITTO.ACCOUNT.NAME')"></b-form-input>
          </b-form-group>
        </b-col>

        <b-col md="6" sm="12">
          <b-form-group :label="$t('KVITTO.ACCOUNT.EXTERNAL_ID')">
            <b-form-input :disabled="true" v-model="local_item.external_id" type="text" :placeholder="$t('KVITTO.ACCOUNT.EXTERNAL_ID')"></b-form-input>
          </b-form-group>
        </b-col>

      </b-row>

      <b-row>

        <b-col md="6" sm="12">
          <b-form-group :label="$t('KVITTO.CREATED_AT')">
            <b-form-input :disabled="true" v-model="local_item.created_at" type="text" :placeholder="$t('KVITTO.CREATED_AT')"></b-form-input>
          </b-form-group>
        </b-col>

        <b-col md="6" sm="12">
          <b-form-group :label="$t('KVITTO.REGISTERED_AT')">
            <b-form-input :disabled="true" v-model="local_item.registered_at" type="text" :placeholder="$t('KVITTO.REGISTERED_AT')"></b-form-input>
          </b-form-group>
        </b-col>


        <b-col md="6" sm="12">
          <b-form-group
            :label="$t('KVITTO.ACCOUNT.ASSIGNED_USER_ID')">
              <b-form-input :disabled="true" v-model="local_item.assigned_user.email" type="text" :placeholder="$t('KVITTO.ACCOUNT.ASSIGNED_USER_ID')"></b-form-input>
            </b-form-group>
        </b-col>

      </b-row>


      <p style="margin-top: 42px;"></p>

      <b-row>
        <b-col>
          <RightModalSaveAndCloseButtons
            :text="$t('COMMON.OK')"
            :spin="true"
            @clicked="on_submit"
            @close="$emit('cancel')"
            ref="saveButton" />
        </b-col>
      </b-row>
    </b-form>

  </b-modal>
</template>

<script>

// axios is REST API
import axios from 'axios';

// popup messages in the corner
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

// vuex state, storage
import { mapGetters } from 'vuex';
import RightModalSaveAndCloseButtons from '@/view/components/buttons/RightModalSaveAndCloseButtons.vue';


export default {
  name: 'AccountTransactionModal',
  mixins: [ toasts ],
  props: ['item'],
  emits: ['created', 'updated'],
  components: {
    RightModalSaveAndCloseButtons
  },
  
  
  computed: {
    // which stored data we want to use
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'settings', 'periods'])
  },
  mounted() {
    if (this.item) {
      this.local_item = { ...this.item };
    }
  },
  data() {
    return {
      local_item: {}
    };
  },
  watch: {
    item: {
      deep: true, // check properties of the form, not just the form reference (example.name for example)
      handler(val) {
        if (this.item) {
          this.local_item = { ...this.item };
        }
      }
    },

    currentCompanyId() {
      // triggers when user has changed company on top
      // we should reload content based on selected company
    },
    currentPeriodId() {
      // triggers when user has changed period on top
      // we should reload content based on period
    }

  },
  methods: {
    on_submit() {
      this.hide();
    },
    // add any methods we need
    show() {
      this.$refs['transaction-edit-modal'].show();
    },

    hide() {
      this.$refs['transaction-edit-modal'].hide();
    }
  }
};
</script>
