<template>
  <b-modal size="sm" ref="transaction-edit-modal" hide-footer>

    <p style="font-weight: bold;">{{ $t('KVITTO.SELECT_ACCOUNT') }}</p>

    <b-row>
      <b-col>
        <b-form-group
         :label="$t('KVITTO.SELECT_ACCOUNT_LABEL')">
          <b-form-select
            v-model="local_item.account_id"
            :options="account_options"
            >
          </b-form-select>
        </b-form-group>
      </b-col>
    </b-row>
    

    <p style="margin-top: 42px;"></p>

      <b-row>
        <b-col>
          <RightModalSaveAndCloseButtons
            :text="$t('COMMON.CONTINUE')"
            :spin="true"
            :disabled="!local_item.account_id"
            @clicked="on_submit"
            @close="$refs['transaction-edit-modal'].hide()"
            ref="saveButton" />
        </b-col>
      </b-row>
  </b-modal>
</template>

<script>

// axios is REST API
import axios from 'axios';

// popup messages in the corner
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

// vuex state, storage
import { mapGetters } from 'vuex';

import RightModalSaveAndCloseButtons from '@/view/components/buttons/RightModalSaveAndCloseButtons.vue';


export default {
  name: 'UploadTransactionsFileModal',
  mixins: [ toasts ],
  props: ['item'],
  emits: ['created', 'updated'],
  components: {
    RightModalSaveAndCloseButtons
  },
  computed: {
    // which stored data we want to use
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'settings', 'periods', 'customer']),
    account_options() {
      return this.accounts.map(item => ({ text: item.name, value: item.id }));
    }
  },
  mounted() {
    this.load_accounts();
  },
  data() {
    return {
      accounts: [],
      local_item: {
        account_id: null
      }
    };
  },
  watch: {
    currentCompanyId() {
      // triggers when user has changed company on top
      // we should reload content based on selected company
    },
    currentPeriodId() {
      // triggers when user has changed period on top
      // we should reload content based on period
    }

  },
  methods: {

    on_submit() {
      if (this.local_item.account_id) {
        this.$emit('account_selected', this.local_item.account_id);
        this.$refs['transaction-edit-modal'].hide();
      }
    },

    email_updated(item) {
      this.$emit('updated', item);
    },

    email_created(item) {
      this.$emit('created', item);
    },

    async load_accounts() {
      const res = await axios.get(`/account/customer/${this.customer.id}`);

      if (res.status === 200) {
        this.accounts = res.data;
      }
    },

    // add any methods we need
    show() {
      this.$refs['transaction-edit-modal'].show();
    },

    hide() {
      this.$refs['transaction-edit-modal'].hide();
    }
  }
};
</script>
