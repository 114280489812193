<template>
  <div class="no-padding">
    <div style="height: 100%;">

      <b-form class="mt-8">

        <b-row>
          <b-col md="6" sm="12">
            <b-form-group :label="$t('KVITTO.INCOMING_EMAIL.FROM')">
              <b-form-input v-model="local_item.from" type="text" :placeholder="$t('KVITTO.INCOMING_EMAIL.FROM')"></b-form-input>
            </b-form-group>
          </b-col>

          <b-col md="6" sm="12">
            <b-form-group :label="$t('KVITTO.INCOMING_EMAIL.ORIGINAL_EMAIL')">
              <b-form-input v-model="local_item.original_email" type="text" :placeholder="$t('KVITTO.INCOMING_EMAIL.ORIGINAL_EMAIL')"></b-form-input>
            </b-form-group>
          </b-col>

        </b-row>

        <b-row>

          <b-col md="12" sm="12">
            
            <b-form-group
              :label="$t('KVITTO.INCOMING_EMAIL.TEXT_BODY')">

              <b-textarea
                rows="12"
                v-model="local_item.text_body"
                >
              </b-textarea>

            </b-form-group>

          </b-col>

        </b-row>

        
        <p style="margin-top: 42px;"></p>

        <b-row>
          <b-col>
            <RightModalSaveAndCloseButtons
              :text="$t('COMMON.OK')"
              :spin="true"
              @clicked="on_submit"
              @close="$emit('cancel')"
              ref="saveButton" />
          </b-col>
        </b-row>
      </b-form>

    </div>
  </div>
</template>


<script>


import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import { mapGetters } from 'vuex';

import axios from 'axios';
import dayjs from 'dayjs';

import RightModalSaveAndCloseButtons from '@/view/components/buttons/RightModalSaveAndCloseButtons.vue';
import TipLabel from '@/view/components/TipLabel.vue';

export default {

  name: 'IncomingEmailsEditor',

  props: ['item'],
  emits: ['updated', 'cancel'],
  mixins: [ toasts ],

  components: {
    RightModalSaveAndCloseButtons,
    TipLabel
  },

  watch: {

    'local_item.assigned_company_id'() {
      this.load_users_for_company(this.local_item.assigned_company_id);
    },

    item: {
      deep: true, // check properties of the form, not just the form reference (example.name for example)
      handler(val) {
        this.local_item = { ...this.item };

        if (this.local_item && this.local_item.assigned_company_id) {
          this.load_users_for_company(this.local_item.assigned_company_id);
        }
      }
    }
  },


  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'companies', 'periods', 'settings', 'customer'])
  },

  async mounted() {

    if (this.item) {
      this.local_item = { ...this.item }
    }

    this.company_options = this.companies.map((item) => ({ text: item.name, value: item.company_id }));

  },

  methods: {

    async load_users_for_company(company_id) {
      this.user_options = [];
      
      const res = await axios.get(`/access/users?company_id=${company_id}`);

      if (res.status === 200) {
        this.user_options = res.data.map((item) => ({ text: item.email, value: item.user_id }));
      }
    },


    async on_submit() {

      this.$refs['saveButton'].stop();
    },

  },


  data() {
    return {

      company_options: [],

      user_options: [],

      local_item: {},

    };
  }
};

</script>

<style lang="scss" scoped>
@import "@/assets/sass/components/forms/_compact.scss";
</style>
