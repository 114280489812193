<template>
  <div class="no-padding">
    <div style="height: 100%;">

      <b-form class="mt-8">

        <b-row>
          <b-col md="6" sm="12">
            <b-form-group :label="$t('KVITTO.TITLE')">
              <b-form-input v-model="local_item.title" type="text" :placeholder="$t('KVITTO.TITLE')"></b-form-input>
            </b-form-group>
          </b-col>

          <b-col md="6" sm="12">
            <b-form-group :label="$t('KVITTO.CREATED_AT')">
              <b-form-input v-model="local_item.created_at" type="text" :placeholder="$t('KVITTO.CREATED_AT')"></b-form-input>
            </b-form-group>
          </b-col>

        </b-row>

        <b-row>

          <b-col md="6" sm="12">
            
            <b-form-group
              :label="$t('KVITTO.ACCOUNT.ASSIGNED_USER_ID')">

              <b-form-select
                v-model="local_item.assigned_user_id"
                :options="user_options"
                >
              </b-form-select>

            </b-form-group>

          </b-col>

          <b-col md="6" sm="12">
            <b-form-group
              :label="$t('KVITTO.DATA_STATUS')">

              <b-form-input
                v-model="local_item.data_status"
                >
              </b-form-input>

            </b-form-group>
          </b-col>

        </b-row>

        <b-row>

          <b-col md="6" sm="12">
            <b-form-group
              :label="$t('KVITTO.DATA_ERROR')">

              <b-form-input
                v-model="local_item.data_error"
                >
              </b-form-input>

            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col md="4" sm="12">
            <b-form-group :label="$t('KVITTO.AMOUNT')">
              <b-form-input v-model="local_item.amount" type="text" :placeholder="$t('KVITTO.AMOUNT')"></b-form-input>
            </b-form-group>
          </b-col>

          <b-col md="4" sm="12">
            <b-form-group :label="$t('KVITTO.AMOUNT_VAT')">
              <b-form-input v-model="local_item.amount_vat" type="text" :placeholder="$t('KVITTO.AMOUNT_VAT')"></b-form-input>
            </b-form-group>
          </b-col>

          <b-col md="4" sm="12">
            <b-form-group :label="$t('KVITTO.CURRENCY')">
              <b-form-input v-model="local_item.currency" type="text" :placeholder="$t('KVITTO.CURRENCY')"></b-form-input>
            </b-form-group>
          </b-col>

        </b-row>

        <b-row >
          <b-col md="6" sm="12">
            <b-form-group :label="$t('KVITTO.INCOMING_EMAIL.ID')">
              <a v-if="local_item.inbound_email_id" @click.prevent="goto_inbound_email">{{ $t('KVITTO.VIEW_INBOUND_EMAIL') }}</a>
              <p v-else>{{ $t('KVITTO.NO_INBOUND_EMAIL') }}</p>
            </b-form-group>
          </b-col>
        </b-row>

        
        <p style="margin-top: 42px;"></p>

        <b-row>
          <b-col>
            <RightModalSaveAndCloseButtons
              :text="$t('COMMON.SAVE')"
              :spin="true"
              @clicked="on_submit"
              @close="$emit('cancel')"
              ref="saveButton" />
          </b-col>
        </b-row>
      </b-form>

    </div>
  </div>
</template>


<script>


import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import { mapGetters } from 'vuex';

import axios from 'axios';
import dayjs from 'dayjs';

import RightModalSaveAndCloseButtons from '@/view/components/buttons/RightModalSaveAndCloseButtons.vue';
import TipLabel from '@/view/components/TipLabel.vue';

export default {

  name: 'AccountsEditor',

  props: ['item'],
  emits: ['updated', 'cancel'],
  mixins: [ toasts ],

  components: {
    RightModalSaveAndCloseButtons,
    TipLabel
  },

  watch: {

    'local_item.assigned_company_id'() {
      this.load_users_for_company(this.local_item.assigned_company_id);
    },

    item: {
      deep: true, // check properties of the form, not just the form reference (example.name for example)
      handler(val) {
        this.local_item = { ...this.item };

        if (this.local_item && this.local_item.assigned_company_id) {
          this.load_users_for_company(this.local_item.assigned_company_id);
        }
      }
    }
  },


  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'companies', 'periods', 'settings', 'customer'])
  },

  async mounted() {

    if (this.item) {
      this.local_item = { ...this.item }
    }

    this.company_options = this.companies.map((item) => ({ text: item.name, value: item.company_id }));

    this.load_users_for_company(this.currentCompanyId);
  },

  methods: {

    async load_users_for_company(company_id) {
      this.user_options = [];
      
      const res = await axios.get(`/access/users?company_id=${company_id}`);

      if (res.status === 200) {
        this.user_options = res.data.map((item) => ({ text: item.email, value: item.user_id }));
      }
    },


    async on_submit() {

      if (!this.local_item.id) {
        this.post_kvitto();
      }
      else {
        this.put_kvitto();
      }

      this.$refs['saveButton'].stop();
    },

    async post_kvitto() {
      const res = await axios.post(`/kvitto/${this.customer.id}`, this.local_item);

      if (res.status === 201) {
        this.local_item = res.data;

        this.$emit('created', this.local_item);
      }
    },

    async put_kvitto() {
      const res = await axios.put(`/kvitto/${this.local_item.id}`, this.local_item);

      if (res.status === 204) {
        this.$emit('updated', this.local_item);
      }
    }

  },


  data() {
    return {

      company_options: [],

      user_options: [],

      local_item: {},

    };
  }
};

</script>

<style lang="scss" scoped>
@import "@/assets/sass/components/forms/_compact.scss";
</style>
