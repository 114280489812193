<template>
  <b-modal size="lg" ref="kvitto-edit-modal" hide-footer>
    <KvittoRuleEditor
      :item="item"
      @updated="kvitto_updated"
      @created="kvitto_created"
      @cancel="()=>{this.$refs['kvitto-edit-modal'].hide();}"
    />
  </b-modal>
</template>

<script>

// axios is REST API
import axios from 'axios';

// popup messages in the corner
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

// vuex state, storage
import { mapGetters } from 'vuex';

import KvittoRuleEditor from '@/view/pages/ml/kvitto/KvittoRuleEditor.vue';

export default {
  name: 'KvittoRuleEditorModal',
  mixins: [ toasts ],
  props: ['item'],
  emits: ['created', 'updated'],
  components: {
    KvittoRuleEditor
  },
  computed: {
    // which stored data we want to use
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'settings', 'periods'])
  },
  mounted() {

  },
  data() {
    return {

    };
  },
  watch: {
    currentCompanyId() {
      // triggers when user has changed company on top
      // we should reload content based on selected company
    },
    currentPeriodId() {
      // triggers when user has changed period on top
      // we should reload content based on period
    }

  },
  methods: {

    kvitto_updated(item) {
      this.$emit('updated', item);
    },

    kvitto_created(item) {
      this.$emit('created', item);
    },

    // add any methods we need
    show() {
      this.$refs['kvitto-edit-modal'].show();
    },

    hide() {
      this.$refs['kvitto-edit-modal'].hide();
    }
  }
};
</script>
