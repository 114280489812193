<template>
  <b-modal size="lg" ref="file-edit-modal" hide-footer>
    <TransactionFileEditor
      :item="item"
      @changed="file_changed"
      @updated="file_updated"
      @cancel="()=>{this.$refs['file-edit-modal'].hide();}"
    />
  </b-modal>
</template>

<script>

// axios is REST API
import axios from 'axios';

// popup messages in the corner
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

// vuex state, storage
import { mapGetters } from 'vuex';

import TransactionFileEditor from '@/view/pages/ml/kvitto/TransactionFileEditor.vue';

export default {
  name: 'TransactionFileEditorModal',
  mixins: [ toasts ],
  props: ['item'],
  emits: ['changed', 'updated'],
  components: {
    TransactionFileEditor
  },
  computed: {
    // which stored data we want to use
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'settings', 'periods'])
  },
  mounted() {

  },
  data() {
    return {

    };
  },
  watch: {
    currentCompanyId() {
      // triggers when user has changed company on top
      // we should reload content based on selected company
    },
    currentPeriodId() {
      // triggers when user has changed period on top
      // we should reload content based on period
    }

  },
  methods: {

    file_changed(item) {
      this.$emit('changed', item);
    },

    file_updated(item) {
      this.$emit('updated', item);
    },

    // add any methods we need
    show() {
      this.$refs['file-edit-modal'].show();
    },

    hide() {
      this.$refs['file-edit-modal'].hide();
    }
  }
};
</script>
