<template>
  <div class="no-padding">
    <div style="height: 100%;">
      <b-form class="mt-8">

        <!-- Row 1: Name and Company ID -->
        <b-row>
          <b-col md="6" sm="12">
            <b-form-group :label="$t('KVITTO_RULE.NAME')">
              <b-form-input v-model="local_item.name" type="text" :placeholder="$t('KVITTO_RULE.NAME')"></b-form-input>
            </b-form-group>
          </b-col>

          <b-col md="6" sm="12">
            <b-form-group :label="$t('KVITTO_RULE.COMPANY_ID')">
              <b-form-select
                v-model="local_item.company_id"
                :options="company_options"
              ></b-form-select>
            </b-form-group>
          </b-col>
        </b-row>

        <!-- Row 2: From Original Email and From Email -->
        <b-row>
          <b-col md="6" sm="12">
            <b-form-group :label="$t('KVITTO_RULE.FROM_ORIGINAL_EMAIL')">
              <b-form-input v-model="local_item.from_original_email" type="email" :placeholder="$t('KVITTO_RULE.FROM_ORIGINAL_EMAIL')"></b-form-input>
            </b-form-group>
          </b-col>

          <b-col md="6" sm="12">
            <b-form-group :label="$t('KVITTO_RULE.FROM_EMAIL')">
              <b-form-input v-model="local_item.from_email" type="email" :placeholder="$t('KVITTO_RULE.FROM_EMAIL')"></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>

        <!-- Row 3: Subject Like and AI Match Description -->
        <b-row>
          <b-col md="6" sm="12">
            <b-form-group :label="$t('KVITTO_RULE.SUBJECT_LIKE')">
              <b-form-input v-model="local_item.subject_like" type="text" :placeholder="$t('KVITTO_RULE.SUBJECT_LIKE')"></b-form-input>
            </b-form-group>
          </b-col>

          <b-col md="6" sm="12">
            <b-form-group :label="$t('KVITTO_RULE.AI_MATCH_DESCRIPTION')">
              <b-form-textarea
                v-model="local_item.ai_match_description"
                :placeholder="$t('KVITTO_RULE.AI_MATCH_DESCRIPTION')"
                rows="3"
              ></b-form-textarea>
            </b-form-group>
          </b-col>
        </b-row>

        <!-- Row 4: Forward Email To -->
        <b-row>
          <b-col md="6" sm="12">
            <b-form-group :label="$t('KVITTO_RULE.FORWARD_EMAIL_TO')">
              <b-form-input v-model="local_item.forward_email_to" type="email" :placeholder="$t('KVITTO_RULE.FORWARD_EMAIL_TO')"></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>

        <!-- Save and Cancel Buttons -->
        <b-row>
          <b-col>
            <RightModalSaveAndCloseButtons
              :text="$t('COMMON.SAVE')"
              :spin="true"
              @clicked="on_submit"
              @close="$emit('cancel')"
              ref="saveButton" />
          </b-col>
        </b-row>

      </b-form>
    </div>
  </div>
</template>

<script>
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import { mapGetters } from 'vuex';
import axios from 'axios';
import dayjs from 'dayjs';
import RightModalSaveAndCloseButtons from '@/view/components/buttons/RightModalSaveAndCloseButtons.vue';
import TipLabel from '@/view/components/TipLabel.vue';

export default {
  name: 'KvittoRuleEditor',
  props: ['item'],
  emits: ['created', 'updated', 'cancel'],
  mixins: [toasts],
  components: {
    RightModalSaveAndCloseButtons,
    TipLabel,
  },
  data() {
    return {
      local_item: {},
      company_options: [],
    };
  },
  computed: {
    ...mapGetters(['currentCompanyId', 'companies', 'customer']),
  },
  watch: {
    item: {
      immediate: true,
      deep: true,
      handler(val) {
        this.local_item = { ...this.item };
      },
    },
  },
  async mounted() {
    this.company_options = this.companies.map((item) => ({ text: item.name, value: item.company_id }));
  },
  methods: {
    
    async on_submit() {
      
      if (!this.local_item.id) {
        await this.post_kvitto_rule();
      } else {
        await this.put_kvitto_rule();
      }

      this.$refs['saveButton'].stop();
    },

    async post_kvitto_rule() {
      try {
        const res = await axios.post(`/kvitto/rule/company/${this.local_item.company_id}`, this.local_item);
        if (res.status === 201) {
          this.local_item = res.data;
          this.$emit('created', this.local_item);
          this.toastr('success', this.$t('COMMON.OK'), this.$t('KVITTO_RULE.CREATED'));
        }
      } catch (error) {
        this.toastr('danger', this.$t('COMMON.FAIL'), this.$t('KVITTO_RULE.UNABLE_CREATE'));
      }
    },

    async put_kvitto_rule() {
      try {
        const res = await axios.put(`/kvitto/rule/${this.local_item.id}`, this.local_item);
        if (res.status === 200) {
          this.$emit('updated', this.local_item);
          this.toastr('success', this.$t('COMMON.OK'), this.$t('KVITTO_RULE.UPDATED'));
        }
      } catch (error) {
        this.toastr('danger', this.$t('COMMON.FAIL'), this.$t('KVITTO_RULE.UNABLE_UPDATE'));
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/sass/components/forms/_compact.scss";
</style>
